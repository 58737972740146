<div class="header text-justify container-fluid d-flex justify-content-center pl-0 navbar1" id="firstnavbar" dir="auto">

  <nav class="navbar navbar-expand-lg p-0 text-justify" dir="auto" id="navbar1">
    <!-- Toggler/collapsibe Button -->
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span>{{'header.menu' | translate}}</span>
    </button> -->

    <!-- Navbar links -->
    <div class="collapse navbar-collapse " id="collapsibleNavbar">
      <ul class="navbar-nav text-justify" dir="auto">
        <li class="nav-item">
          <a class="nav-link">
            <!-- <label class="form-control" for="lang" style="font-weight: bold;color: darkblue; display: inline;">{{'header.Lang' | translate}}:
              <select style="border: none;" id="lang" #langSelect (change)="translate.use(langSelect.value)">
                <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                  [attr.selected]="lang === translate.currentLang ? '' : null">{{lang}}</option>
              </select>
            </label> -->
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://capitaladvantagetutors.com"> 
            <span class="border-left">
             {{'header.VIP' | translate}}
           </span> 
         </a> 
       </li>
        <!-- <li class="nav-item">
          <a class="nav-link " href="https://capitaladvantagetutors.com/"> 
            <span class="border-left">
              {{'About.AboutUs' | translate}}
            </span> 
          </a> 
        </li> -->
        <!-- <li class="nav-item">
           <a class="nav-link" href="https://capitaladvantagetutors.com/landing-page3"> 
             <span class="border-left">
              {{'header.VIP' | translate}}
            </span> 
          </a> 
        </li> -->
        <li class="nav-item">
           <a class="nav-link" href="https://capitaladvantagetutors.com/landing-page3"> 
             <span class="border-left">
              {{'header.FindStore' | translate}}
            </span> 
          </a> 
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="https://capadvantagetutors.com/landing-page8"> 

             <span class="border-left">
              {{'header.LIFE' | translate}} 
            </span> 
            </a>
        </li> -->
    <!-- <li class="nav-item">
           <a class="nav-link" href="https://capadvantagevendors.com/"> 
             <span class="border-left">
              {{'header.MyBag' | translate}}
            </span> 
          </a> 
        </li> -->
                <li class="nav-item">
          <a class="nav-link " href="https://capitaladvantagetutors.com/landing-page9"> 
            <span class="border-left">
              {{'About.AboutUs' | translate}}
            </span> 
          </a> 
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="https://capadvantagetutors.com/landing-page9"> 

          <span class="border-left">
           {{'header.LIFE' | translate}} 
         </span> 
         </a>
     </li> -->
     <li class="nav-item">
      <a class="nav-link" href="https://capitaladvantagetutors.com/pages/contact-us"> 

      <span class="border-left">
       {{'header.Shop' | translate}} 
     </span> 
     </a>
 </li>
 <li class="nav-item">
  <a class="nav-link" href="https://capitaladvantagetutors.com/pages/career"> 

  <span class="border-left">
   {{'header.Interests' | translate}} 
 </span> 
 </a>
</li>
  
        <!-- <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">
            <span style="padding-left: 0.75rem" id="kidszone">
              <img src="assets\images\kids-071ec87d4b946d9b953fb44fed61a8ff.png">
              {{'header.KIDS' | translate}}
              <svg width="15" height="16px" class="UtilityBarstyles__StyledButtonIcon-sc-1uwh8t8-8 cJlqSa"
                viewBox="0 0 37 16" aria-hidden="true" data-di-res-id="414e8f1b-8dab17b8" data-di-rand="1608395651180">
                <path fill="#2C2C2C"
                  d="M29.4934,0.597299272 L29.587579,0.680519939 L36.122774,7.22016518 C36.4829618,7.58059825 36.5106685,8.14750912 36.2058942,8.53970801 L36.122774,8.6338973 L29.587579,15.1735425 C29.1971877,15.5641997 28.5640228,15.5644152 28.1733656,15.1740239 C27.8127589,14.8136627 27.7848363,14.2464411 28.0897277,13.8540461 L28.1728842,13.7598104 L33.002375,8.926386 L1.095375,8.92703124 C0.54309025,8.92703124 0.095375,8.47931599 0.095375,7.92703124 C0.095375,7.4141954 0.48141519,6.99152408 0.978753875,6.93375897 L1.095375,6.92703124 L33.002375,6.926386 L28.1728842,2.09425206 C27.812523,1.73364542 27.7849865,1.16640496 28.0901449,0.774217598 L28.1733656,0.680038581 C28.5339722,0.319677338 29.1012127,0.292140874 29.4934,0.597299272 Z">
                </path>
              </svg>
            </span>
          </a>
        </li> -->
      </ul>
    </div>
  </nav>
  <ul class="nav" id="navbar12">
    <li class="nav-item">
      <a class="nav-link px-0" [routerLink]="['/EditAccount']">
        <span class="font-weight-bold">
          <img src="assets\images\newlogo.png">
          <span>{{'header.Account' | translate}}</span>
        </span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link px-0" [routerLink]="['/WishList']">
        <!-- <span class="font-weight-bold ">
          <img src="assets\images\wishlist-6a9c0bed142a5eac6ded4132391e5e55.png">
          <span>{{'header.Wish' | translate}} </span>
        </span> -->
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link px-0" [routerLink]="['/MyBBag']">
        <span class="font-weight-bold">
          <!-- <img src="assets\images\bag-486c00daf1b11d5a5700f4842a176ad6.png">
          <span>{{'header.MyBag' | translate}}</span> -->
        </span>
      </a>
    </li>
  </ul>
</div>
<!--End of first navbar-->

<!--Second navbar-->
<div class="container-fluid  d-flex justify-content-between align-items-center"
  style="background-color: rgb(147, 150, 158);" id="secondnavbar" dir="auto">
  <nav class="navbar navbar-expand-sm p-0 ">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/Home']">
      <img src="assets\images\newlogo.png" width="100px" alt="logo" id="logo">
    </a>

    <!-- Links -->
    <ul class="navbar-nav">

      <li class="nav-item">
        <!-- <a class="nav-link" [routerLink]="['/Themes']">
          {{'header.Themes' | translate}}
        </a> -->
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          {{'header.Shop' | translate}}
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          {{'header.Interests' | translate}}
        </a>
      </li> -->
      <li class="nav-item">
        <!-- <a class="nav-link" [routerLink]="['/Offers&Sales']">
          {{'header.OfferSale' | translate}}
        </a> -->
      </li>

      <!-- <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          {{'header.Exclusives' | translate}}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          {{'header.Support' | translate}}
        </a>
      </li> -->
    </ul>
  </nav>
  <!-- <div class="secondnavbar">
    <button class="d-sm-none d-md-block" style="width: 40px;height: 40px; border-radius:50%; border:none">
      <i class="fas fa-search"></i>
    </button>
  </div> -->
</div>
<!--End of second navbar-->

<!--start-->
<div class="container-fluid navbargdeda" style="border-bottom: 2px solid rgb(255,207,0);" dir="auto">

  <div id="mySidenav" class="sidenav" #mySidenav>
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()" style="border-bottom: none;">&times;</a>
    <a href="javascript:void(0)">
      <div style="padding-left: 0.75rem ;" id="kidszone2">
        <img src="assets\images\kids-071ec87d4b946d9b953fb44fed61a8ff.png">
        {{'header.KIDS' | translate}}
        <svg width="15" height="16px" class="UtilityBarstyles__StyledButtonIcon-sc-1uwh8t8-8 cJlqSa" viewBox="0 0 37 16"
          aria-hidden="true" data-di-res-id="414e8f1b-8dab17b8" data-di-rand="1608395651180">
          <path fill="#2C2C2C"
            d="M29.4934,0.597299272 L29.587579,0.680519939 L36.122774,7.22016518 C36.4829618,7.58059825 36.5106685,8.14750912 36.2058942,8.53970801 L36.122774,8.6338973 L29.587579,15.1735425 C29.1971877,15.5641997 28.5640228,15.5644152 28.1733656,15.1740239 C27.8127589,14.8136627 27.7848363,14.2464411 28.0897277,13.8540461 L28.1728842,13.7598104 L33.002375,8.926386 L1.095375,8.92703124 C0.54309025,8.92703124 0.095375,8.47931599 0.095375,7.92703124 C0.095375,7.4141954 0.48141519,6.99152408 0.978753875,6.93375897 L1.095375,6.92703124 L33.002375,6.926386 L28.1728842,2.09425206 C27.812523,1.73364542 27.7849865,1.16640496 28.0901449,0.774217598 L28.1733656,0.680038581 C28.5339722,0.319677338 29.1012127,0.292140874 29.4934,0.597299272 Z">
          </path>
        </svg>
      </div>
    </a>
    <a [routerLink]="['/Home']" class="font-weight-bold cap">{{'header.Home' | translate}}</a>
    <a [routerLink]="['/Themes']" class="font-weight-bold cap">{{'header.Themes' | translate}}</a>
    <a href="javascript:void(0)" class="font-weight-bold cap">{{'header.Shop' | translate}}</a>
    <a href="javascript:void(0)" class="font-weight-bold cap">{{'header.Interests' | translate}}</a>
    <a [routerLink]="['/Offers&Sales']" class="font-weight-bold cap">{{'header.OfferSale' | translate}}</a>
    <a href="javascript:void(0)" class="font-weight-bold cap"> {{'header.Exclusives' | translate}}</a>
    <a href="javascript:void(0)" class="font-weight-bold cap"> {{'header.Support' | translate}}</a>
    <a [routerLink]="['/EditAccount']">
      <span class="font-weight-bold">
        <img src="assets\images\account-747c349c92ac5a41cc81e55c9e9b74b7.png">
        <span>{{'header.MyAccount' | translate}} </span>
      </span>
    </a>
    <a [routerLink]="['/WishList']">
      <span class="font-weight-bold">
        <img src="assets\images\wishlist-6a9c0bed142a5eac6ded4132391e5e55.png">
        <span>{{'header.MyWishlist' | translate}} </span>
      </span>
    </a>
    <!-- <a [routerLink]="['/Admin']">
      <span class="font-weight-bold">
        <img src="assets\images\us-4ebaed6a76e4d7930e0b2c9e86a43389.png">
        Admin Dashboard
      </span>
    </a> -->
    
    <a [routerLink]="['/About']">
      <span class="font-weight-bold">
        <img src="assets\images\aboutus-7416dc11232ec122683d85e4781bee75.png">
        {{'About.AboutUs' | translate}}
      </span>
    </a>
    <a [routerLink]="['/VIP']">
      <span class="font-weight-bold">
        <img src="assets\images\vip-f2b35d0b39794494b5d5cb40fe256517.png">
        {{'header.VIP' | translate}}
      </span>
    </a>
    <a [routerLink]="['/FindStore']">
      <span class="font-weight-bold">
        <img src="assets\images\store-fce72c21fae5db22f1335fe436da787a.png">
        {{'header.FindStore' | translate}}
      </span>
    </a>
    <a [routerLink]="['/LegoLife']">
      <span class="font-weight-bold">
        <img src="assets\images\legolife-c0398949fb45f6e81badb6cfd03af5eb.png">
        {{'header.LIFE' | translate}} 
      </span>
    </a>


  </div>





</div>

<style>
  .blah{
    text-decoration: bold;
  }
</style>