<!--start-->
<div class="container-fluid text-justify" dir="auto" id="banner">
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner text-center">
      <div class="carousel-item active">
        <p>
          <img src="assets\images\newlogo.png">
          <small>{{'Home.data'|translate}}</small>
        </p>

      </div>
      <div class="carousel-item">
        <p>
          <img src="assets\images\newlogo.png">
          <small>{{'Home.Skating'|translate}}</small>
        </p>
      </div>

    </div>
    <!-- <a class="carousel-control-prev pb-3" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">{{'RecommendedForYou.Previous'|translate}}</span>
    </a> -->
    <a class="carousel-control-next pb-3" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon " aria-hidden="true"></span>
      <span class="sr-only">{{'RecommendedForYou.Next'|translate}}</span>
    </a>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid cover">
  <div class="row">
    <div>
      <picture>
        <source media="(min-width:901px)" srcset="assets\images\nyse.jpg"
          style="width:100%">
        <source media="(min-width:601px)" srcset="assets\images\nyse.jpg"
          style="width:100%">
        <source media="(max-width:601px)" srcset="assets\images\nyse.jpg"
          style="width:100%">
        <img src="assets\images\nyse.jpg" style="width:100%">
      </picture>
      <div class="coverxs d-block d-sm-none" style="background-color: rgb(0,32,87); color: white;">
        <div>
          <!-- <button type="button" class="btn btn-light px-3 py-2 m-2">
            <svg width="16" height="16"
              class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
              viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
              <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
            </svg>
          </button> -->
         
        </div>
      </div>
      <div class="d-none d-sm-block d-md-none" style="background-color: rgb(0,32,87); color: white; ">
        <div>
          <button type="button" class="btn btn-light px-3 py-2 m-2">
            <svg width="16" height="16"
              class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
              viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
              <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
            </svg>
          </button>
         
        </div>
      </div>

      <!-- <div class="covercontent d-none d-md-block ">
        <h1 class="text-justify" dir="auto">{{'Home.Guaranteed'|translate}}</h1>
        <span class="text-justify" dir="auto">{{'Home.Shipping'|translate}}</span>
        <div>
          <button type="button" class="btn btn-light px-3 py-2 m-2">
            <strong>{{'VIP.shop'|translate}}</strong>
            <svg width="16" height="16"
              class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
              viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
              <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
            </svg>
          </button>
          <button type="button" class="btn btn-light px-3 py-2 m-2">
            <strong>{{'header.FindStore'|translate}}</strong>
            <svg width="16" height="16"
              class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
              viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
              <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--end-->

<!--start-->
<!-- <div class="container">
  <div class="row4 py-5 d-flex justify-content-center text-center flex-wrap">
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\novelty-2020-06-Quicklink.jpg">
        <div>
          <small>{{'Home.News'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\853967-Home-202011-Quicklink.png">
        <div>
          <small>{{'header.Exclusives'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\40416-HomePage-202011-Quicklink.png">
        <div>
          <small>{{'Offers.Offers'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\front-QL.jpg">
        <div>
          <small>{{'Home.Holiday'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\bfcm-gift-card-Quicklink.png">
        <div>
          <small>{{'footer.Gift'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\BestSeller-Home-202010-Quicklink.png">
        <div>
          <small>{{'Home.Sellers'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\Quicklink-pick-a-brick.jpg">
        <div>
          <small>{{'Home.Brick'|translate}}</small>
        </div>
      </a>
    </div>
    <div class="p-3">
      <a href="javascript:void(0)">
        <img src="assets\images\VIPSoftLaunch-Quicklink.jpg">
        <div>
          <small>{{'header.VIP'|translate}}</small>
        </div>
      </a>
    </div>

  </div>
</div> -->
<!--end-->

<br>
<br>
<app-recommended-products></app-recommended-products>
<!-- <app-recommended-products></app-recommended-products> -->

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<!--start-->


<!--end-->


<!--start-->

<!--end-->

<!--start-->

    <!-- <div class="col-lg-4 col-md-4 col-sm-4  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\41913-Home-202006-Block-Standard.jpeg">
      <h5 class="mb-3">{{'Home.Create'|translate}}</h5>
      <p class="m-0">{{'Home.Give'|translate}}</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\75969-HP-202006-Block-Standard.jpeg">
      <h5 class="mb-3">{{'Home.Expand'|translate}}</h5>
      <p class="m-0">{{'Home.fans'|translate}}
      </p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div> -->




  </div>
</div>
<!--end-->


<!--start-->
<!-- <div class="container-fluid my-5 p-0 animationSection">
  <video width="100%" loop="true" autoplay="autoplay">
    <source src="assets\images\51515-MB-Hero-Standard.mp4" type="video/mp4">
    <source src="assets\images\51515-MB-Hero-Standard.ogg" type="video/ogg">
    {{'Home.video'|translate}}
  </video>
  <picture id="robotimage">
    <source media="(max-width:600px)" srcset="assets\images\51515-MB-Home-202010-Hero-Standard-Small.jpeg">
    <source media="(min-width:901px)" srcset="assets\images\51515-MB-Home-202010-Hero-Standard-Large.jpeg">
    <source media="(min-width:601px)" srcset="assets\images\51515-MB-Home-202010-Hero-Standard-Medium.jpeg">
    <img src="assets\images\40416-HomePage-202011-Hero-Standard-Large.jpg" alt="Flowers" style="width:auto;">
  </picture>

  <div class="animationSectionContainer text-white">
    <h2 class="text-justify" dir="auto">{{'Home.Robot'|translate}}</h2>
    <span class="text-justify" dir="auto">{{'Home.kids'|translate}}</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2" style="color:black; background-color: white;">
        {{'VIP.shop'|translate}}
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>

  <div class="animationSectionContainersmall py-3">
    <h5>{{'Offers.REE'|translate}}</h5>
    <span class="text-justify" dir="auto">{{'Home.exclusive'|translate}}*</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2" style="color:black; background-color: white;">
        {{'VIP.shop'|translate}}
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>



</div> -->
<!--end-->

<!--start-->
<!-- <div class="container-fluid Trending">
  <h2 class="my-3 text-justify" dir="auto">{{'Home.Spotlight'|translate}}</h2>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\5006230-home-202011-Block-Mixed-Large.jpeg">
      <h5 class="mb-3">{{'Home.hang'|translate}}</h5>
      <p class="m-0">{{'Home.space'|translate}}</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\Keyrings-Product-Home-2003--Block-Standard.jpeg">
      <h5 class="mb-3">{{'Home.love'|translate}}</h5>
      <p class="m-0">{{'Home.keyring'|translate}}</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
  </div>
</div> -->
<!--end-->

<!--start-->
<!-- <div class="container-fluid row7img mt-5 p-0">
  <picture>
    <source media="(min-width:901px)" srcset="assets\images\45678LS-Home-2020-Hero-Standard-Large.jpeg">
    <source media="(min-width:601px)" srcset="assets\images\45678LS-Home-2020-Hero-Standard-Medium.jpeg">
    <source media="(max-width:600px)" srcset="assets\images\45678LS-Home-2020-Hero-Standard-Small.jpeg">
    <img src="assets\images\40416-HomePage-202011-Hero-Standard-Large.jpg" alt="Flowers" style="width:auto;">
  </picture> -->
  <!-- <div class="row7container">
    <h1 class="text-justify" dir="auto">{{'Home.learning'|translate}}</h1>
    <p class="text-justify" dir="auto">{{'Home.forFirst'|translate}}</p>
    <div>
      <button type="button" class="btn px-3 py-2 m-2 text-white" style="background-color: black;">
        {{'VIP.shop'|translate}}
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
      <button type="button" class="btn px-3 py-2 m-2 text-white" style="background-color: black;">
        {{'VIP.more'|translate}}
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div> -->
  <!-- <div class="row7containersmall py-3" style="background-color: rgb(243, 243, 243);">
    <h5>{{'Offers.REE'|translate}}</h5>
    <span class="text-justify" dir="auto">{{'Home.exclusive'|translate}}*</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2 text-white" style="background-color: black;">
        <strong>{{'VIP.shop'|translate}}</strong>
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>
</div> -->
<!--end-->